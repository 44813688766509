<template>
	<div class="ct_box" v-loading.fullscreen.lock="fullscreenLoading">
		<div class="ct_title_box flex">
			<p class="ct_title">选择需要开票的订单</p>
			<div class="search-status_box flex">
				<div class="search_box flex">
					<el-date-picker value-format="timestamp" @change="timerFn" v-model="time" type="daterange"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
					<div class="order_search flex">
						<img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
						<el-input class="order_search_inp" v-model="orderId" placeholder="输入标题/订单号"></el-input>
						<button class="search_but" @click="listFn">搜索</button>
					</div>
				</div>
			</div>
		</div>
		<div style="position: relative;">
			<div style="padding-bottom:60px;box-sizing: border-box;">
				<table class="table" >
					<thead>
						<tr>
							<td>选择</td>
							<td>订单号</td>
							<td>金额</td>
							<td>时间</td>
							<!-- <td>备注</td> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in tableDate" :key="index">
							<td>
								<el-checkbox v-model="item.check"></el-checkbox>
							</td>
							<td>{{ item.sn }}</td>
							<td>{{ item.medium_price }}</td>
							<td>{{ item.ctime }}</td>
							<!-- <td>xxxxxxxx</td> -->
						</tr>
					</tbody>
				</table>
			</div>
			<!-- 分页器 -->
			<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
		</div>
		<div class="form_box">
			<div class="title_box flex">
				<p class="ct_title">填写开票信息</p>
				<button class="but_or" @click="submitFn">提交</button>
			</div>
			<div class="flex" style="align-items: center; margin-bottom: 2rem">
				<p class="radio_title">开票主体及类型：</p>
				<div class="flex radio_box">
					<el-radio v-model="form.radio" :label="item" v-for="(item, index) in RadioType" :key="index">{{
		item.title }}</el-radio>
				</div>
			</div>
			<div class="flex" style="align-items: center; margin-bottom: 2rem">
				<p class="radio_title">选择开票标题：</p>
				<div class="radio_box">
					<!-- {{ form.type }} -->
					<el-select @change="InvoiceLs" v-model="form.type" placeholder="请选择">
						<el-option v-for="item in Invoiceform" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
					<!-- <el-input v-model="invoiceId" placeholder="请输入内容"></el-input> -->
					<p class="remark">
						如果没有开票信息，请先
						<span style="cursor: pointer" @click.stop="$router.push('/user/AddInvoice')">添加开票资料</span>
					</p>
				</div>
			</div>
			<div class="flex" style="align-items: center; margin-bottom: 2rem">
				<p class="radio_title">发票内容：</p>
				<div class="flex radio_box">
					<el-radio v-model="form.invoiceContent" :label="1">信息技术服务费</el-radio>
					<el-radio v-model="form.invoiceContent" :label="2">网络推广服务费</el-radio>
				</div>
			</div>
			<div class="flex" style="align-items: center; margin-bottom: 2rem">
				<p class="radio_title">开票金额：</p>
				<div class="radio_box">
					<el-input v-model="invoiceMoney" placeholder="自动识别选择的订单金额，不可填写" readonly></el-input>
				</div>
			</div>
			<div class="flex" style="align-items: center; margin-bottom: 2rem">
				<p class="radio_title">扣税点金额：</p>
				<div class="radio_box">
					<el-input v-model="deductTheTax" placeholder="自动计算，不可填写" readonly></el-input>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	computed: {
		...mapState(['ApplyForInvoiceTitle']),
		invoiceMoney: function () {
			// console.log(navVal, oldVal);
			var n = 0;
			if (this.tableDate != []) {
				for (var i = 0; i < this.tableDate.length; i++) {

					if (this.tableDate[i].check) {
						n += parseInt(this.tableDate[i].medium_price);
					}
				}
			}
			return n.toFixed(2);
		},
		deductTheTax: function () {
			// console.log(navVal, oldVal);
			var n = 0;
			console.log(this.form.radio.points);
			n = this.invoiceMoney * this.form.radio.points
			return n.toFixed(2);
		}

	},
	mounted() {
		this.$store.commit('childTab', '申请发票')
		this.listFn()
	},
	data() {
		return {
			fullscreenLoading: false,
			time: '', //时间
			orderId: '', //订单号
			RadioType: [],
			form: {
				type: '', //开票标题
				radio: {}, //开票标题类型
				invoiceContent: 1, //开票内容
				order_ids: '',
			},
			// invoiceMoney: '', //发票金额
			// deductTheTax: '', //扣税点数
			total_page: 0, //分页总页数
			count: 0, //总条数
			invoice: [], //开票标题列表
			Invoiceform: [],
			value: '',
			tableDate: [],
		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.listFn()
		},
		listFn() {
			this.fullscreenLoading = true
			this.curlGet('/api/users/invoice/order').then((res) => {
				if (res.data.code) {
					this.tableDate = res.data.data.list
					this.tableDate.forEach((item, index) => {
						this.$set(item, 'check', false)
					})
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.fullscreenLoading = false
				}
			})
			this.curlGet('/api/tax').then((res) => {
				// console.log(res);
				if (res.data.code) {
					this.RadioType = res.data.data
					this.form.radio = this.RadioType[0]
				}
			})
			let data = {}
			if (this.orderId != '') {
				data.header = this.orderId
			}
			if (this.time != '') {
				data.start_time = this.time[0]
				data.end_time = this.time[1]
			}
			this.curlGet('/api/users/invoice_info').then((res) => {
				res.data.data.list.forEach((item, index) => {
					this.Invoiceform.push({
						id: item.id,
						title: item.header,
					})
				})
			})
		},
		InvoiceLs(val) {
			let resultArr = this.Invoiceform.filter((item) => {
				return item.id === val
			})
			this.form.type = resultArr[0]
		},
		// 切换页
		currentTab(val) {
			console.log('切换')
			this.page = val
			this.listFn()
		},
		// 上一页
		prevClick() {
			console.log('up')
			this.page -= 1
			this.listFn()
		},
		// 下一页
		// next-click
		nextClick() {
			console.log('down')
			this.page += 1
			this.listFn()
		},
		// 选中日期后的回调
		timerFn() {
			console.log(this.time)
			this.listFn()
		},
		// 提交
		submitFn() {
			if (this.form.type == '') {
				return this.$message({
					type: 'warning',
					message: '请选择开票标题',
				})
			}
			let order_ids = ''
			this.tableDate.forEach((item, index) => {
				if (item.check) {
					order_ids += `${item.id},`
				}
			})
			if (order_ids == '') {
				return this.$message({
					type: 'warning',
					message: '请选择需要开票的订单',
				})
			}
			console.log(this.form.type)
			this.fullscreenLoading = true
			this.curlPost('/api/users/invoice/save', {
				style: this.form.radio.id,
				info_id: this.form.type.id,
				type: this.form.invoiceContent,
				order_ids: order_ids,
			}).then((res) => {
				console.log(res)
				if (res.data.code) {
					this.$message({
						type: 'success',
						message: '发票申请成功,等待审核',
					})
					this.listFn()
					// this.$store.state.ApplyForInvoiceTitle = '我的开票资料'
					this.$router.push('/user/Invoice')
				} else {
					this.$message({
						type: 'warning',
						message: res.data.msg,
					})
				}
			})
			this.fullscreenLoading = false
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/ApplyForInvoice';
</style>